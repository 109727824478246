<template>
  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-[5rem]">
        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-10 pb-5">
          <div class="mb-4 md:mb-0 bg-brand-black rounded-2xl p-8">
            <div class="w-[33px] h-[3px] bg-[#FDE442] mb-2"></div>

            <h1 class="mb-3 text-white leading-[130%] text-[21px]">Candidate Signup</h1>

            <p class="text-white mb-5 text-[16px] leading-[130%]">Create a profile and find your next opportunity</p>

            <div>
              <a :href="$candidateUrl + '/register'" class="text-center text-[#FDE442]">Sign up to get hired</a>
            </div>
          </div>

          <div class="mb-4 md:mb-0 bg-brand-black rounded-2xl p-8">
            <div class="w-[33px] h-[3px] bg-[#00F5D4] mb-2"></div>

            <h1 class="mb-3 text-white leading-[130%] text-[21px]">Employer Signup</h1>

            <p class="text-white mb-5 text-[16px] leading-[130%]">Sign up and find your next team member. You can use the self serve</p>

            <div>
              <a :href="$employerUrl + '/register'" class="text-center text-[#00F5D4]">Sign up hire</a>
            </div>
          </div>

          <div class="mb-4 md:mb-0 bg-brand-black rounded-2xl p-8">
            <div class="w-[33px] h-[3px] bg-[#FE6D74] mb-2"></div>

            <h1 class="mb-3 text-white leading-[130%] text-[21px]">Start Hiring</h1>

            <p class="text-white mb-5 text-[16px] leading-[130%]">Reach out to our team to instantly connect you to top talents you want to hire.</p>

            <div>
              <router-link to="/contact" class="text-center text-[#FE6D74]">See how it works</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>
