<template>
  <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-10 pb-5">
    <div class="mb-4 md:mb-0 bg-brand-black rounded-2xl p-8">
      <div class="text-center">
        <span class="bg-white inline-block px-8 py-3 rounded-lg mx-auto">BETTER TALENT</span>
        <p class="text-[30px] text-white my-3 py-[.2rem] block">Top 1%</p>
      </div>

      <p class="text-white mb-5">
        All candidates in our pipeline have gone through a rigorous screening process. We only accept the best 1% of candidates that apply
      </p>

      <div>
        <a href="/contact" class="w-full block rounded-lg px-8 py-3 text-center bg-white">Get started</a>
      </div>
    </div>

    <div class="mb-4 md:mb-0 bg-brand-black rounded-2xl p-8">
      <div class="text-center">
        <span class="bg-white inline-block px-8 py-3 rounded-lg mx-auto">FAST HIRING</span>
        <p class="text-[30px] text-white my-3 py-[.2rem] block">Hire within days</p>
      </div>

      <p class="text-white mb-5">
        Spend less time interviewing and more time building by connecting with talent who have undergone our rigorous vetting process.
      </p>

      <div>
        <a href="/contact" class="w-full block rounded-lg px-8 py-3 text-center bg-white">Get started</a>
      </div>
    </div>

    <div class="mb-4 md:mb-0 bg-brand-black rounded-2xl p-8">
      <div class="text-center">
        <span class="bg-white inline-block px-8 py-3 rounded-lg mx-auto">LOWER COST</span>
        <p class="text-[30px] text-white my-3 py-[.2rem] block">Save Over 80%</p>
      </div>

      <p class="text-white mb-5">Reduce hiring cost and overhead by 5x. You can hire amazing full-time employees for around $9,000 USD per year</p>

      <div>
        <a href="/contact" class="w-full block rounded-lg px-8 py-3 text-center bg-white">Get started</a>
      </div>
    </div>
  </div>
</template>
